import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'In the News and Press Releases',
      paragraph: 'Recent news and press releases from ROSA, her employees and partners. In the past month ROSA affiliate, Phosphorus, was approved for a lab-based PCR test for SARS-CoV-2, and ROSA co-founder Sarthak Sawarkar\'s work on a non-invasive AMH test was accepted by ESHRE.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — In low-prevalence communities, Pooled Testing offers a 90% reduction in testing cost per patient by pooling samples. This is accomplished by taking “gold standard” RT-PCR testing technology, which detects the active presence of SARS-CoV-2, and adapting the lab protocol to allow samples to be pooled together.
                  </p>
                  <span className="testimonial-date">August 1, 2020</span>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Sarthak Sawarkar</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.prweb.com/releases/rosa_scientific_announces_partnership_with_san_francisco_based_zouves_foundation_for_reproductive_medicine_zfrm_towards_co_development_of_a_pooled_covid_19_diagnostic/prweb17296975.htm">PRWeb</a>
                  </span>
                </div>
              </div>
            </div>

              <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — The prospect of a non-invasive test of ovarian reserve is a little closer following results from a study showing that measurement of a fertility hormone can be accurately taken from a sample of human hair.
                    </p>
                    <span className="testimonial-date">July 6, 2020</span>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Sarthak Sawarkar</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.eshre.eu/ESHRE2020/Media/2020-Press-releases/Sawarkar">ESHRE</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Instead of running a coronavirus test on every specimen that arrives in a lab, take a sample of that specimen and combine it with samples from other specimens. Then run a single test on that pooled sample.
                        If it comes back negative, you can assume that all of the original samples are negative.
                      </p>
                      <span className="testimonial-date">July 6, 2020</span>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Richard Harris</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.npr.org/sections/health-shots/2020/07/06/886886255/pooling-coronavirus-tests-can-spare-scarce-supplies-but-theres-a-catch">NPR</a>
                    </span>
                  </div>
                </div>
              </div>

              {/*}<div className="tiles-item reveal-from-left" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Phosphorus, a leading genomics firm headquartered in New York City with a laboratory in Secaucus, New Jersey, announced today that the U.S. Food and Drug Administration (FDA) granted the Emergency Use Authorization request for its saliva test for COVID-19 that features at-home sample collection.
                      </p>
                    <span className="testimonial-date">June 8, 2020</span>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high"></span>
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.prnewswire.com/news-releases/phosphorus-announces-fda-approval-of-at-home-saliva-test-for-covid-19-301071525.html">Phosphorus</a>
                    </span>
                  </div>
                </div>
              </div>*/}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
