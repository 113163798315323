import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Team from '../components/sections/Team';
// import required elements
import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';

// Import Helmet
import Helmet from "react-helmet";


class TeamView extends React.Component {

    state = {
      videoModalActive: false
    }
    openModal = (e) => {
      e.preventDefault();
      this.setState({ videoModalActive: true });
    }

    closeModal = (e) => {
      e.preventDefault();
      this.setState({ videoModalActive: false });
    }

  render() {

    const videoSectionHeader = {
      title: 'Our Mission',
      paragraph: 'We want to make predictive and preventive medicine possible, make it easier to develop human diagnostics for diseases that elude easy differentiation, and ultimately contribute to a better understanding complex diseases.'
    }

    return (
      <React.Fragment>
      <Helmet>
        <title>ROSA Scientific | Team</title>
      </Helmet>
      <GenericSection topDivider className="center-content">
        <SectionHeader data={videoSectionHeader} className="reveal-from-bottom" />
        <div className="reveal-from-bottom">
          <a
            data-video="https://player.vimeo.com/video/437268387"
            href="#0"
            aria-controls="video-modal"
            onClick={this.openModal}
          >
            <Image
              src={require('./../assets/images/video-placeholder.svg')}
              alt="Video"
              width={712}
              height={400} />
          </a>
        </div>
        <Modal
          id="video-modal"
          show={this.state.videoModalActive}
          handleClose={this.closeModal}
          video="https://player.vimeo.com/video/437268387"
          videoTag="iframe" />
      </GenericSection>
      <Team topDivider />
      <div style={{ height: '100px'}}></div>
      </React.Fragment>
    );
  }
}


export default TeamView;
