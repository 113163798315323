import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import FertilitySplit from '../components/sections/FertilitySplit';
import Testimonial from '../components/sections/FertilityTestimonial';
// import required elements
import Image from '../components/elements/Image';

// Import Helmet
import Helmet from "react-helmet";

const fertilityStyles = `
    body { background-color: white; }
    body { background: linear-gradient(0deg, #FFEBAF, white) no-repeat; }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color: #63728E; }
    .rosa-orange { color: #f7c041; }
    h2.mt-0.mb-16 { color: #ffd500; }
    .image-larger { width: calc(160% + 96px); }
    .has-top-divider::before { background: #f7c041 !important };
`

class PlatformView extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>ROSA Fertility | NICS</title>
          <style>{fertilityStyles}</style>
        </Helmet>

        <GenericSection>
          <div className="container-xs center-content">
            <h2 className="mt-0 rosa-orange">NICS: Non-invasive Chromosomal Screening</h2>
            <h3 className="mt-0">A new genetic test that increases ivf success rates, and minimizes embryo damage</h3>
            {/* <p>
              A new genetic test that increases ivf success rates, and minimizes embryo damage.
            </p> */}
            <figure>
              <Image
                className="image-medium"
                src={require('./../assets/images/eggabstract.png')}
                alt="Placeholder"
                width={712}
                height={400} />
            </figure>
          </div>
        </GenericSection>

        <FertilitySplit invertMobile topDivider imageFill />

        <GenericSection topDivider>
          <div className="container-xs center-content">
            <h2 className="mt-0 rosa-orange">Whether it is 1 embryo or 100, we support the smallest of clinics and have no batching requirements.</h2>
          </div>
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs center-content">
            <h2 className="mt-0">THE PROCESS</h2>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/nicsmethod.png')}
                alt="Placeholder"
                width={712}
                height={400} />
            </figure>
          </div>
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0 center-content">THE TECHNOLOGY</h2>
            <p>
              NICS makes use of a proprietary amplification method, MALBAC, and Next Generation Sequencing to delivery the highest throughput assay with superior sensitivity and specificity. The MALBAC amplification platform ensures that only the origical genomic DNA is amplified. NICS can detect the following:
            </p>
            <ul>
              <li>Aneuploidy</li>
              <li>Partial (segmental) aneuploidy</li>
              <li>Some polyploidies</li>
              <li>Mosaicism</li>
            </ul>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/nicstechnology.png')}
                alt="Placeholder"
                width={712}
                height={400} />
            </figure>
          </div>
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs center-content">
            <h2 className="mt-0 rosa-orange">Our labs are CLIA certified, and can turn around results between 24 hours and 7 days.</h2>
          </div>
        </GenericSection>

        <Testimonial topDivider />

      </React.Fragment>
    );
  }
}


export default PlatformView;
