import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class ColorimetrySplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Make Any Diagnostic a Smart Test',
      paragraph: 'Paper-based immunoassays (P-ELISA, LFA, and μPAD) are inexpensive to develop, deliver quick results at point of care (eg in-center, at home) and can be evaluated without skilled technicians. As a result, they are widespread and found in many DTC applications, everything from fertility to virology.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    Quantitative
                  </h3>
                  <p></p>
                  <p className="m-0">
                    By design such tests are binary, and provide only qualitative results.
                  </p>
                  <p></p>
                  <p className="m-0">
                    A smart test can leverage the benefits of existing assays, while providing the capacity to add support for quantitation.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/quantitation.png')}
                    alt="Quantitative Colorimetry"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    Accuracy
                  </h3>
                  <p className="m-0">
                    Lack of quantification reduces accuracy of result analysis, and potentially the efficacy of subsequent therapeutics.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/measure.png')}
                    alt="Colorimetry Accuracy"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    Connected
                  </h3>
                  <p className="m-0">
                    A side-effect is that point of care testing reduces feedback to the researcher or other calibration opportunities to the manufacture.
                  </p>
                  <p></p>
                  <p>
                    Whether aimed at clinical trials or consumer tests, a smart test is connected and provides a framework for meta-analysis, performance feedback, and results sharing.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image-01.svg')}
                    alt="Connected Colorimetry Smart-tests"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ColorimetrySplit.propTypes = propTypes;
ColorimetrySplit.defaultProps = defaultProps;

export default ColorimetrySplit;
