import React from 'react';
import { Router, withRouter, Switch } from 'react-router-dom';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutFertility from './layouts/LayoutFertility';
import LayoutBasic from './layouts/LayoutBasic';

// Views
import Home from './views/Home';
// import Team from './views/Team';
import Roadmap from './views/Roadmap';
import Platform from './views/Platform';
import Covid from './views/Covid';
import Colorimetry from './views/Colorimetry';
import Search from './views/Search';
import Fertility from './views/Fertility';
import Fulfill from './views/Fulfill';
import Sama from './views/sama';

import { LOCATION_URL, EXC, objHas, BlkContext } from './utils/loc';

// import ReactGA from 'react-ga';
//   componentDidMount() {
//     ReactGA.initialize('UA-172428127-1');
//     ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory()
ReactGA.initialize('UA-172428127-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
});


class App extends React.Component {

  state = { blk: false }

  componentDidMount() {
    // ReactGA.initialize('UA-172428127-1');
    // fetch(LOCATION_URL).then(res => res.text())
    //   .then(data => {
    //     console.log("TESTING ", data);
    //     let loc = JSON.parse(data["slice"](2,-2));
    //     window.LOC = loc;
    //     if (Object.keys(EXC).some(key => objHas(EXC, loc)(key))) {
    //       console.log("bok");
    //       this.setState({blk: true});
    //     }
    // });
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <BlkContext.Provider value={this.state}>
       <Router history={this.props.history}>
        <ScrollReveal
          ref="scrollReveal"
          children={() => (
            <Switch>
              <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              <AppRoute exact path="/roadmap" component={Roadmap} layout={LayoutAlternative} blk={this.state.blk} />
              <AppRoute exact path="/our-platform" component={Platform} layout={LayoutAlternative} />
              <AppRoute exact path="/smart-tests" component={Colorimetry} layout={LayoutAlternative} />
              <AppRoute exact path="/search" component={Search} layout={LayoutAlternative} />
              <AppRoute exact path="/fertility" component={Fertility} layout={LayoutFertility} />
              <AppRoute exact path="/fulfill" component={Fulfill} layout={LayoutBasic} />
              <AppRoute exact path="/sama" component={Sama} layout={LayoutBasic} />
              {/*<AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />*/}
            </Switch>
          )} />
        </Router>
      </BlkContext.Provider>
    );
  }
}

export default withRouter(props => <App {...props} />);
