import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

class Cta extends React.Component {

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      open: false,
      phone: false,
      status: ""
    };
  }

  onEmailFocus = () => {
    this.setState({
      open: true
    });
  }

  onPhoneFocus = () => {
    this.setState({
      phone: true
    });
  }

  onSubmit = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    // console.log(form);
    this.submitForm(form);
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile reveal-from-bottom',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    const { open, status } = this.state;
    const loading = status === "LOADING" ? true : false;
    const formError = status === "ERROR" ? "Unable to submit contact request." : "";
    const formSuccess = status === "SUCCESS" ? true : false;

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h3 className="m-0">
                {formSuccess ? "We'll be in touch soon." : "Looking for a diagnostic partner?"}
              </h3>
            </div>
            <div className="cta-action">
                {!open ?
                      <Input
                        onFocus={ this.onEmailFocus }
                        id="email"
                        type="email"
                        label="Subscribe"
                        labelHidden
                        hasIcon="right"
                        disabled={formSuccess}
                        placeholder="Your email">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                        </svg>
                      </Input>
                :
                <form onSubmit={this.onSubmit}
                      action="https://formspree.io/mvowvpoq"
                      method="POST">
                  <Input autoFocus
                    id="email" name="email"  type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your email"></Input>
                  <Input
                    id="name" name="name"  type="text" label="Subscribe" labelHidden hasIcon="right" placeholder="Your full name"></Input>
                  <Input onFocus={ this.onPhoneFocus } hint={formError}
                    id="phone" name="phone"  type="tel" label="Subscribe" labelHidden hasIcon="right"
                    placeholder={this.state.phone ? "(609) 123-5555" : "Best number to reach you"}>
                  </Input>
                  <Button loading={loading}
                    id="submit" type="submit" label="Subscribe" color="secondary" wideMobile >Submit</Button>
                </form>
                }
            </div>
          </div>
        </div>
      </section>
    );
  }

  submitForm(form) {
      this.setState({ status: "LOADING" });
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.setState({ status: "SUCCESS" });
          this.setState({ open: false });
        } else {
          this.setState({ status: "ERROR" });
        }
      };
      xhr.send(data);
    }

}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
