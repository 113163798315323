import React from 'react';
// import sections
import FeaturesTiles from '../components/sections/FeaturesTiles';
import GenericSection from '../components/sections/GenericSection';
// import required elements
import Image from '../components/elements/Image';

// Import Helmet
import Helmet from "react-helmet";


class PlatformView extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>ROSA Scientific | Platform</title>
        </Helmet>

        <FeaturesTiles topDivider className="center-content illustration-section-02" />

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 style={{ textAlign: 'center' }} className="mt-0">ROSA Co-development</h2>
            <h4>Companion Diagnostics</h4>
            <p>
              Through feasibility, clinical-care pathway evaluation, discovery and clinical validation of proteomic signatures, ROSA offers full stack IVD product development services in accordance with US FDA Design Control and CE-IVD requirements.
            </p>
            <h4>Stage-gating</h4>
            <p>
              Through stage-gating, we aim to create a capital efficient process, by combining the benefits of cumulative dependencies found in traditional development with faster learning from highly iterative strategies in use by modern software engineering.
            </p>
            <p>
              Such iterative strategies are founded on "fail fast" principles, allowing earlier discovery of feasibility challenges.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/rosa-circles.svg')}
                alt="Placeholder"
                width={712}
                height={400} />
              {/*<figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption>*/}
            </figure>
            <h4>Analyte Detection</h4>
            <p>
              At the core of ROSA is a proprietary technique for massively parallel analyte detection. Using the fundamentals of microfluidics,
              genomics, proteomics, and data analysis allows low-cost
              detection and quantification.
            </p>
            <ul>
              <li>Detect as many as 500 analytes at a time, using in-house expertise to generate monoclonal/polyclonal antibodies</li>
              <li>Detect extremely low concentrations</li>
              <li>No extensive sample preparation required</li>
              <li>Adaptable to existing lab equipment</li>
              <li>Simultaneous detection of carbohydrates, proteins, glycoproteins </li>
              <li>Fully customizable</li>
              <li>Human and animal diagnostics</li>
              <li>Generate databases of novel population-wide data</li>
            </ul>
          </div>
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">ROSA's Platform</h2>
            <p>
              Our platform provides for a modern capital-efficient process for developing diagnostics:
            </p>
            <ul>
              <li>Uses stage-gating for significant risk reduction</li>
              <li>Leverages our antibody core</li>
              <li>Massively parallel analyte detection reduces cost</li>
              <li>Emphasis on computational biology allows for scalable development</li>
            </ul>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/platformB.png')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">Our Platform <span role="img" aria-label="mile">🧪</span></figcaption>
            </figure>
            <h4>Disease Proteomics Database</h4>
            <p>
            Proteomics offers tremendous toolsets that can be applied to the discovery of disease markers, allowing for rapid identification and quantification of proteins for numerous complex diseases that lack targeted therapeutics. Recently, proteomics has become focused on clinical research disease pathogenesis, to better understand disease-related mechanisms and clinical biomarker discovery.
            </p><p>
            Our proteome database is an unbiased, high-throughput analysis pipeline for protein-protein interaction, protein domain, gene ontology, cross-proteome comparisons, and tissue expression profiles. We integrate protein data sets with known disease biomarker candidates, which we hope will lead to the discovery of novel disease biomarkers that can be utilized for detection, prognostication, and treatment of diseases.
            </p>

            <h4>Antibody Repository</h4>
            <p>
            The ROSA Antibody repository aims at generating high quality monoclonal and polyclonal antibodies for specific applications (including basic and translational research or for therapeutic purposes) by providing expert advice and quality service on project design, discussing the proper approach, while saving time and money for researchers in a timely manner. We aim at maintaining a massive library of antibodies & nanobodies that can be seamlessly integrated within our diagnostic platform when required.
            </p>

            <h4>Disease Modeling</h4>
            <p>
            Complex diseases do not obey the standard Mendelian patterns of inheritance. Although we inherit genes associated with these diseases, genetic factors represent only small part of the risk associated with complex disease phenotypes. A genetic predisposition means that an individual has a genetic susceptibility to developing a certain disease, but this does not mean that a person harboring a genetic tendency is destined to develop the disease.
            </p>

            <h4>Manufacturing</h4>
            <p>
            ROSA has partnered with one of the leading manufacturers of diagnostic devices in India. We have leveraged the latest and best technologies and manufacturing processes from electronics, information technology, and biotechnology to create products and provide solutions that contribute to human and animal health sector. Our partner operates a state of the art manufacturing facility which is ISO 13485:2016 certified and is approved by CDSCO, India, for manufacture of diagnostics. The R&D division of the company is approved the DSIR, Ministry of Science and Technology, Government of India.
            </p>

            <h4>Regulatory</h4>
            <p>
            ROSA, through our in-house FDA practice, provides strategic in-house and outside consultation for developments subject to FDA oversight.
            </p><p>
            We partner with startups, researchers and large organizations involved in pharmaceutical, medical device, biologics, diagnostics, and clinical laboratory on a wide range of matters involving the FDA and other government agencies, from clinical development and marketing authorization strategies to post-marketing compliance.
            </p><p>
            We handle interactions with federal and state regulators who oversee product development, manufacturing, and marketing activities. We are closely involved in pre-market strategies and implementation, including product development, clinical investigations, review and approval pathways, orphan drug and other designations, premarket applications (e.g., NDAs, 505(b)(2)s, ANDAs, BLAs, biosimilars applications, 510(k)s, de novo requests, and PMAs), dispute resolution and appeals, commercial launch preparation, and intellectual property assessment.
            </p>

            <h4>Clinical</h4>
            <p>
            Diagnostics, and medical devices generally, have become more complex in recent years, mandating not just scientific approaches, but strategic ones, towards risk management. A compelling advantage of ROSA's platform is the ability to cast a wide net, without the increased financial cost. Prior to advancing to the development stage, ROSA leverages this capacity alongside a stage-gating strategy that evaluates not just market feasibility and manufacturing feasibility, but the role in clinical pathways, general viability and competitiveness.
            </p>

          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}


export default PlatformView;
