import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitCovid extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'COVID-19 Diagnostics',
      paragraph: 'There isn\'t a one-size fits all for diagnostics. Additionally, we must consider the cost, result latency, test performance (accuracy, sensitivity), and the logistics of availability and distribution. Furthermore, effective testing depends on the stage of exposure and illness, which isn\'t often known.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    COVID-19 PCR Testing
                  </h3>
                  <p className="m-0">
                    The so-called "gold standard" of testing, PCR is a highly reliable method of detecting active infection. ROSA is working with a company that has a COVID-19 RT-qPCR Test was developed and is performed at a CLIA Laboratory in the US. The test is a real-time reverse transcription polymerase chain reaction (RT-qPCR) test. It was developed to detect the presence of two separate regions of the SARS-CoV-2 viral RNA.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/pcr.jpg')}
                    alt="Coronavirus PCR Testing"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    COVID-19 Antibody Testing
                  </h3>
                  <p className="m-0">
                    ROSA has partnered with manufacturers of a lateral flow immuno-assay rapid test for COVID-19 that provides a much easier and faster way to determine previous exposure to the virus. Unlike other antibody tests, the test does not require extensive laboratory operations and can be performed on site, under the supervision of a health care professionals, consistent with the terms of the FDA Authorization for these tests.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/antibodyassay.png')}
                    alt="Coronavirus Antibody Testing"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    COVID-19 Pooling Strategies
                  </h3>
                  <p className="m-0">
                    We at ROSA are using our enhanced data analytics and mathematical expertise to redefine pooling strategies in an effort to bridge the chasm of the required numbers of COVID-19 testing. We have partnered with Zouves Research Foundation for developing novel pooling strategies for COVID-19 testing.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image-02.svg')}
                    alt="Coronavirus Pooled Testing"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplitCovid.propTypes = propTypes;
FeaturesSplitCovid.defaultProps = defaultProps;

export default FeaturesSplitCovid;
