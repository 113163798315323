import React from 'react';
import qs from 'qs';

import SectionHeader from '../components/sections/partials/SectionHeader';
import GenericSection from '../components/sections/GenericSection';

import SearchComponent from '../components/sections/Search';
import { makeSearchableContent } from '../components/elements/SearchHook';


// Search Content
import Home from './Home';
import Team from './Team';
import Roadmap from './Roadmap';
import Platform from './Platform';
import Covid from './Covid';
import Colorimetry from './Colorimetry';

const HomeSearch = makeSearchableContent(Home, <a href="/">Home</a>);
const TeamSearch = makeSearchableContent(Team, <a href="/team/">Team</a>);
const RoadmapSearch = makeSearchableContent(Roadmap, <a href="/roadmap/">Roadmap</a>);
const PlatformSearch = makeSearchableContent(Platform, <a href="/our-platform/">Platform</a>);
const CovidSearch = makeSearchableContent(Covid, <a href="/covid-19/">Covid-19</a>);
const ColorimetrySearch = makeSearchableContent(Colorimetry, <a href="/smart-tests/">Colorimetry</a>);


class Search extends React.Component {

  state = {
    initalSearch: ""
  }

  componentWillMount() {
    const searchTerm = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (searchTerm.q) {
      this.setState({ initalSearch: searchTerm.q });
    }
  }

  render() {

    const searchSection = {
      title: 'Search ROSA'
    }

    const searchItems = [HomeSearch, TeamSearch, RoadmapSearch, PlatformSearch, CovidSearch, ColorimetrySearch];

    return (
      <React.Fragment>

      <GenericSection topDivider>
        <div className="container-xs">
          <SectionHeader data={searchSection} className="center-content" />
          <form style={formStyle}>
            <div className="mb-24">
              <SearchComponent list={searchItems} initial={this.state.initalSearch} />
            </div>
          </form>
        </div>
      </GenericSection>

      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Search;
