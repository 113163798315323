import React from 'react';

import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import List from '../../components/elements/List';

import { useClientSearch } from '../../components/elements/SearchHook';


const SearchComponent = ({ list, initial }) => {
  const {
    query,
    setQuery,
    searchList
  } = useClientSearch(list, initial, listItem => listItem.content);

  const items = searchList.map((item) => item.display);

  return (
    <>
      <Input
        type="text"
        label="Search"
        placeholder="Enter search keywords"
        formGroup="desktop"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        labelHidden>
        <Button color="primary">Search</Button>
      </Input>
      <List items={items} />
    </>
  );
};

export default SearchComponent;
