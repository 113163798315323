
import React from 'react';

const LOCATION_URL = 'https://cors-anywhere.herokuapp.com/https://gd.geobytes.com/GetCityDetails?' // &fqcn=x.x.x.x

// "10522"
// "USORPORA"

const EXC = {
    geobytescityid: ["7228"],
    geobyteslocationcode: ["USCAINGL"],
    geobytescity: ["Los Angeles"]
}

const objHas = (obj, data) => key => obj[key].some(val => val === data[key]);

const BlkContext = React.createContext({ blk: false });

export { LOCATION_URL, EXC, objHas, BlkContext };
