import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplitPlatform from '../components/sections/FeaturesSplitPlatform';
import Testimonial from '../components/sections/Testimonial';
// import required elements
import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';

// Import Helmet
import Helmet from "react-helmet";


class Home extends React.Component {

    state = {
      videoModalActive: false
    }
    openModal = (e) => {
      e.preventDefault();
      this.setState({ videoModalActive: true });
    }

    closeModal = (e) => {
      e.preventDefault();
      this.setState({ videoModalActive: false });
    }

  render() {

    const videoSectionHeader = {
      title: 'On-Demand Diagnostics',
      paragraph: 'We are developing the ROSA on-demand diagnostics platform to address the cost, scalability, and throughput limitations of present diagnostic development processes.'
    }

    const platformSectionHeader = {
      title: 'Our Platform',
      paragraph: 'Has the potential to transform the research of new diseases, diagnostics, and drug development.'
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>ROSA | Diagnostic solutions and proteomics for disease research</title>
        </Helmet>
        <HeroSplit className="illustration-section-bio" />
        <Clients topDivider bottomDivider />
        <GenericSection topDivider className="center-content">
          <SectionHeader data={videoSectionHeader} className="reveal-from-bottom" />
          <div className="reveal-from-bottom">
            <a
              data-video="https://player.vimeo.com/video/437268387"
              href="#0"
              aria-controls="video-modal"
              onClick={this.openModal}
            >
              <Image
                src={require('./../assets/images/video-placeholder.svg')}
                alt="Video"
                width={712}
                height={400} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={this.state.videoModalActive}
            handleClose={this.closeModal}
            video="https://player.vimeo.com/video/437268387"
            videoTag="iframe" />
        </GenericSection>
        <FeaturesSplitPlatform invertMobile topDivider imageFill />
        <GenericSection>
          <div className="container-xs">
            <SectionHeader data={platformSectionHeader} className="center-content" />
            <div className="center-content" style={{'marginTop': '-30px'}}>
              <a href="/our-platform"><Button color="secondary">Learn More</Button></a>
            </div>
          </div >
        </GenericSection>
        <Testimonial topDivider />
      </React.Fragment>
    );
  }
}


export default Home;
