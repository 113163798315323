import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset" style={{float: 'left'}}>
        <li>
          <a href="tel:+1 202-780-7672">Contact Sales:</a> 
          <a href="tel:+1 202-780-7672">+1 (202) 780-ROSA</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterSocial;
