import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
}

const defaultProps = {
  children: null,
  title: ''
}

const TimelineItem = ({
  className,
  children,
  title,
  past,
  current,
  future,
  ...props
}) => {

  const classes = classNames(
    past && 'past',
    current && 'current',
    'timeline-item',
    className
  );

  const headerClasses = classNames(
    current && 'current',
    future && 'future',
    "timeline-item-header tt-u mb-4 reveal-fade"
  );

  const contentClasses = classNames(
    future && 'future',
    "timeline-item-content h4 m-0 reveal-from-side"
  );

  return (
    <div
      {...props}
      className={classes}>
      <div className="timeline-item-inner">
        <div className={headerClasses}>{title}</div>
        <div className={contentClasses}>
          {children}
        </div>
      </div>
    </div>
  );
}

TimelineItem.propTypes = propTypes;
TimelineItem.defaultProps = defaultProps;

export default TimelineItem;
