import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import FertilitySplit from '../components/sections/FertilitySplit';
import Testimonial from '../components/sections/FertilityTestimonial';
// import required elements
import Image from '../components/elements/Image';

// Import Helmet
import Helmet from "react-helmet";

const fertilityStyles = `
    body { background-color: white; }
    .image-larger { width: calc(160% + 96px); }
`

class Sama extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>SAMA | At-home IVF</title>
          <style>{fertilityStyles}</style>
        </Helmet>
        <figure>
          <Image
            style={{"margin": "auto auto"}}
            className="image-medium"
            src={require('./../assets/images/sama-concept.png')}
            alt="Placeholder"
            width={712}
            height={400} />
        </figure>
      </React.Fragment>
    );
  }
}


export default Sama;
