import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import { BlkContext } from '../../utils/loc';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Team extends React.Component {

  static contextType = BlkContext;

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Meet the team - scientists, technologists, and innovators.',
      paragraph: 'ROSA\'s team has led venture-backed organizations, healthcare organizations, and clinical research. Our team includes scientists who have been within the core team that developed the Gardasil vaccine and the founder of the field of embryo genetic testing (PGT). Together we have been awarded dozens of patents, written more than 400 peer reviewed articles, and exited multiple companies.'
    };

    let { context } = this;

    return (
          <section
            {...props}
            className={outerClasses}
          >
            <div className="container">
              <div className={innerClasses}>
                <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
                <div className={tilesClasses}>

                  <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/priya.png"
                            alt="Sudha Haran"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          Priya Haran
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Co-founder
                        </div>*/}
                        <p className="m-0 text-sm">
                          Sudha "Priya" Haran is an immunologist with over a decade of hospital and healthcare management experience paired with a successful project management background. She has worked with one of the largest growing Medical Groups in the state of NJ where she played a role in Strategy and Ops and Business Development. Sudha has a passion for problem solving and has a skill in getting teams to works cohesively at all levels.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/santi.png"
                            alt="Santiago Munne"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          Santiago Munne
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Co-founder
                        </div>*/}
                        <p className="m-0 text-sm">
                          Founder at multiple companies, Dr. Munne co-founded Reprogenetics in 2001, the first commercial lab to offer Preimplantation Genetic Testing (PGT) for couples undergoing infertility treatment or carriers of a genetic disease.  Santiago has also worked as the CSO of CooperGenomics after Reprogenetics and Recombine were bought by Cooper companies. Santiago has published over 260 peer reviewed publications and is currently an adjunct professor at Yale School of Medicine. 
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="tiles-item reveal-from-bottom">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/sarthak.png"
                            alt="Sarthak Sawarkar"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          Sarthak Sawarkar
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Founder & CEO
                        </div>*/}
                        <p className="m-0 text-sm">
                          Sarthak has spent over a decade in healthcare, focusing on several biotech and health tech ventures. His research work has primarily focused on molecular biology and human genetics. He has presented his research at several national and international conferences. Most recently, he developed the first truly non-invasive test for AMH.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="tiles-item reveal-from-bottom">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/neil.png"
                            alt="Neil Christensen"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          Neil Christensen
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Co-founder
                        </div>*/}
                        <p className="m-0 text-sm">
                          Dr. Christensen’s laboratory has spent three decades studying the immunity and pathogenesis of papillomavirus infection. He is a world renowned expert in virology and antibody purification, Fab production, immunoassays and the construction of single-chain variable fragment reagents. Dr. Christensen maintains a library of more than 500 monoclonal antibodies which he has distributed worldwide to more than 50 academic and industry research labs.
                        </p>
                      </div>
                    </div>
                  </div>

              {!context.blk ?
                <React.Fragment>
                  <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/sverma.png"
                            alt="S. Verma"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          S. Verma
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Co-founder
                        </div>*/}
                        <p className="m-0 text-sm">
                          Dr. Verma is an immunologist by training and passionate about bringing the learnings from the basic science to the forefront of diagnostics and drug discovery. With a PhD from Indian Institute of Science (IISC), India and postdoctoral training at the Penn State College of Medicine, he has over 14 years of experience, working in basic and translational research.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                    <div className="tiles-item-inner">
                      <div className="team-item-header">
                        <div className="team-item-image mb-24">
                          <Image
                            src="https://rosascientific.s3-us-west-1.amazonaws.com/public/sams.png"
                            alt="Joshua Sams"
                            width={180}
                            height={180} />
                        </div>
                      </div>
                      <div className="team-item-content">
                        <h5 className="team-item-name mt-0 mb-4">
                          Joshua Sams
                        </h5>
                        {/*<div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                          Co-founder
                        </div>*/}
                        <p className="m-0 text-sm">
                          Healthcare technology executive, and former 500 startups CTO and CEO, TechCrunch 50 CEO, and multiple-venture backed CTO. Previously architected distributed healthcare systems, designed clinical workflow solutions. Led software team behind IRB-approved clinical trials, developed big data genomics pipeline. Experience in highly regulated environments, including advisor to the White House Office of Science and Technology.
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                : null }

                </div>
              </div>
            </div>
          </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
