import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Further Reading',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    - Most prevalent studies in the field have  tested the "non-invasively collected media" against the trophectoderm (TE) biopsy. However the TE biopsy itself is just a snapshot of the actual ploidy level of the embryo. In the Racowsky paper, the authors pursued a three pronged strategy of comparing the the media, a TE biopsy and the whole embryo with each other. For further reading and results.....
                  </p>
                  <span className="testimonial-date">July 9, 2019</span>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Harvard + Peking</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.pnas.org/content/116/28/14105.short">PNAS</a>
                  </span>
                </div>
              </div>
            </div>

              <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — The authors in this publication have shown that standard blastocyst culture medium (bcm) used for applying PGT is suitable for use for PGT-A and PGT-SR. BCM and TE biopsy samples showed 90% and 100% karyotype concordance with the corresponding BE samples, respectively
                    </p>
                    <span className="testimonial-date">July 8, 2019</span>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">National Library</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/31251795/">NIH</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Pilot clinical trial using NICS for examining 413 embryos. Clinical pregnancy rate of almost 60% was observed.
                      </p>
                    <span className="testimonial-date">March 8, 2020</span>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high"></span>
                    {/*<span className="text-color-low"> / </span>*/}
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://translational-medicine.biomedcentral.com/articles/10.1186/s12967-019-1827-1">Journal of Translational Medicine</a>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
