import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';
import GenericSection from '../../components/sections/GenericSection';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>

          <GenericSection topDivider>
            <div className="container-xs center-content">
              <h2 className="mt-0">Roadmap</h2>
              <p>
                We're on a mission to make it easier to develop human diagnostics and open the door to better understanding complex diseases.
              </p>

              <div className="tiles-item reveal-from-right tiles-quote-only" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                  <em>moon·shot</em>
                    <p className="text-sm mb-0">
                       A company 1) focused on a significant technological development or complex coordination and 2) that could have a transformative impact on the world.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-link">
                      <a target="_blank" rel="noopener noreferrer" href="https://apolloprojects.com/">Apollo Projects</a>
                    </span>
                  </div>
                </div>
              </div>
              <p>
                We were inspired to post our Tesla-Roadster milestones, by the “Moonshot” mission of Apollo Projects to fund ambituous companies.
              </p>
              <p>
                The need to select the right milestones is crucial — a Telsa-Roadster strategy. For Telsa, the Roadster represented a premium market subsidizing
                R&D, but the Roadster went much futher by shattering the stereotypes of electric cars altogether.
              </p>
              {/*<p>
                For us, that means developing
             </p>*/}
             <p>
                Below is some of our history and the roadmap to our moonshot.
              </p>
            </div>
          </GenericSection>

            <Timeline>
              <TimelineItem title="JANUARY 2018">
                Concept for novel multiple analyte detection method
              </TimelineItem>
              <TimelineItem title="FEBRUARY 2018">
                Holding company formed
              </TimelineItem>
              <TimelineItem title="FEBRUARY 2018">
                Provisional patent filed
              </TimelineItem>
              <TimelineItem title="August 2018">
                Multiplexing proof of concept developed
              </TimelineItem>
              <TimelineItem title="DECEMEBER 2018">
                Lab protocol developed
              </TimelineItem>
              <TimelineItem title="FEBRUARY 2019">
                Patent for method of multiplexed analyte detection
              </TimelineItem>
              <TimelineItem title="JULY 2019">
                ROSA co-founder Sarthak Sawarkar develops the first truly non-invasive test for AMH
              </TimelineItem>
              <TimelineItem title="MARCH 2020">
                Team formed
              </TimelineItem>
              <TimelineItem title="APRIL 2020">
                Developed pooling method for detection of SARS-CoV-2
              </TimelineItem>
              <TimelineItem title="APRIL 2020">
                Manufacturing partnership with ubio
              </TimelineItem>
              <TimelineItem title="JUNE 2020">
                <span style={{color:'#FF5FA0'}}>
                The ROSA name (and website) is born (named after &nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Rosalind_Franklin">
                   Rosalind Franklin
                </a>)</span>
              </TimelineItem>
              <TimelineItem title="JUNE 2020">
                ROSA joins HoMu Ventures
              </TimelineItem>
              <TimelineItem title="JULY 2020" past>
                Zouves Partnership
              </TimelineItem>
              <TimelineItem title="JULY 2020" current>
                Protocol for SARS-CoV-2 pooling method completed
              </TimelineItem>
              <TimelineItem title="AUGUST 2020" future>
                Develop a scalable method for analyte detection
              </TimelineItem>
              <TimelineItem title="AUGUST 2020" future>
                Establish a boutique Antibody core
              </TimelineItem>
              <TimelineItem title="MARCH 2021" future>
                Semi-automated protocol for multiplexed analyte detection
              </TimelineItem>
              <TimelineItem title="DECEMBER 2021" future>
                Develop a set of biomarker panels to support the research of complex diseases
              </TimelineItem>
              <TimelineItem title="AUGUST 2022" future>
                Automated protocol for multiplexed analyte detection
              </TimelineItem>
              <TimelineItem title="JANUARY 2023" future>
                Our "Tesla Roadster" is a set of automated biomarker panels to support the research of complex diseases
              </TimelineItem>
              <TimelineItem title="JUNE 2025" future>
                Our "Model S" is a diagnostic platform capable of rapidly creating new algorithm-driven tests (as so-called companion diagnostics)
              </TimelineItem>
              <TimelineItem title="JANUARY 2028" future>
                Our Moonshot is a new computational model of complex diseases, that can provide early prediction of pathology and bring about new therapies
              </TimelineItem>
              <TimelineItem title="SOMEDAY" future>
                We hope to substantially contribute to mapping of all human diseases
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}


Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
