import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FertilitySplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Overview',
      paragraph: 'Rosa Fertility is now providing Non-Invasive Chromosomal Screening (NICS), powered by Avrio Genetics. NICS is a best in class preimplantation genetic testing for aneuploidy using only spent culture media, no embryo biopsy required. NICS can increase the likelihood of a successful pregnancy by selecting embryos based on genetics, minimizing damage to embryos.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

            <h2 className="mt-0 rosa-orange center-content">Benefits</h2>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                  No Embryo Biopsy Required
                  </h3>
                  <p></p>
                  <p className="m-0">
                    No biopsy is required. NICS is a completely non-invasive technique that uses spent culture media.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/biopsy.png')}
                    alt="biopsy"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                  PGT-A Testing Improves Success Rates
                  </h3>
                  <p className="m-0">
                    A pilot clinical trial using NICS examined 413 embryos, and resulted in a pregnancy rate of almost 60%.
                  </p>
                  <p></p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/baby.jpg')}
                    alt="baby"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                  Improved Patient Peace of Mind
                  </h3>
                  <p className="m-0">
                    Uncertainty over harmfulness of invasive techniques can weigh on the clinician and mother-to-be alike. NICS removes this doubt.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/heartphoto.png')}
                    alt="ultrasound"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FertilitySplit.propTypes = propTypes;
FertilitySplit.defaultProps = defaultProps;

export default FertilitySplit;
