import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterPhone from './partials/FooterPhone';
import FooterSocial from './partials/FooterSocial';
import FertilityCta from '../../components/sections/FertilityCta';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer',
      'site-footer-with-contact',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <FertilityCta split />
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <FooterPhone />
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Company</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/team/">Team</a>
                    </li>
                    <li>
                      <a href="/roadmap/">Roadmap</a>
                    </li>
                    <li>
                      <a href="/our-platform/">Platform</a>
                    </li>
                    <li>
                      <a href="/smart-tests/">Colorimetry</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">News</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.eshre.eu/ESHRE2020/Media/2020-Press-releases/Sawarkar">AMH Test</a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.npr.org/sections/health-shots/2020/07/06/886886255/pooling-coronavirus-tests-can-spare-scarce-supplies-but-theres-a-catch">COVID-19 Pooling</a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.prnewswire.com/news-releases/phosphorus-announces-fda-approval-of-at-home-saliva-test-for-covid-19-301071525.html">COVID-19 LDT qPCR</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Research</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="#0">Cardiology</a>
                    </li>
                    <li>
                      <a href="#0">PCOS</a>
                    </li>
                    <li>
                      <a href="#0">Fertility</a>
                    </li>
                    <li>
                      <a href="#0">Fibromyalgia</a>
                    </li>
                    <li>
                      <a href="#0">Arthritis</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <div className="footer-copyright">&copy; 2022 G1 Sciences, LLC Princeton, NJ. All Rights Reserved.</div>
              <FooterSocial />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
