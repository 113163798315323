import React from 'react';


const List = ({ items }) => (
  // To-do: add group by item.type
  <ul>
    {items.map((item, i) => (
      <li key={i}>{item}</li>
    ))}
  </ul>
);

export default List;
