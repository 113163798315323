import React from 'react';
// import sections
import Roadmap from '../components/sections/Roadmap';

// Import Helmet
import Helmet from "react-helmet";


class RoadmapView extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>ROSA Scientific | Roadmap</title>
          <meta property="og:image" content="%PUBLIC_URL%/roadmap.png" />
        </Helmet>

        <Roadmap />
        <div style={{ height: '100px'}}></div>
      </React.Fragment>
    );
  }
}


export default RoadmapView;
