import React from 'react';
// import sections
import FeaturesSplitCovid from '../components/sections/FeaturesSplitCovid';
import GenericSection from '../components/sections/GenericSection';
// import required elements
import Image from '../components/elements/Image';

// Import Helmet
import Helmet from "react-helmet";


class CovidView extends React.Component {

  render() {

    return (
      <React.Fragment>

      <Helmet>
        <title>ROSA Scientific | Team</title>
      </Helmet>

        <GenericSection className="illustration-section-covid">
          <div className="container-xs center-content">
            <h2 className="mt-0">SARS-CoV-2</h2>
            <p>
            Over the past months we have seen some unprecedented disruptions caused by COVID-19. Many difficult decisions have had to be taken regarding managing our communities, workplace safety protocols, remote workforces, reduced schedules, layoffs and furloughs. As companies and communities prepare to reopen their work and public spaces, they must consider several specific issues including adherence to strict health measures to limit exposure and transmission of COVID-19.
            </p>
          </div>
        </GenericSection>

        <FeaturesSplitCovid invertMobile topDivider imageFill />

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Strategy</h2>
            <p>
              We at ROSA want to do our part to bring about a paradigm shift in the current testing capabilities in the US. We combine validated testing solutions for both active virus and antibodies, with sensible recommendations for ongoing management along with robust screening programs. We are looking for healthcare partners who will help co-create and study outcomes so that we can improve patient and employee safety for all.
              We are currently focused on combination strategies involving:
            </p>
            <ul>
              <li>PCR Testing</li>
              <li>Antibody Testing</li>
              <li>PCR Pooling Strategies</li>
            </ul>
            <p>
              Additionally, a neutralization assay is also like to play a critical role in the management of testing large populations.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/covid19-chart-dark.png')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">Detectability of SARS-CoV-2 from time of infection.</figcaption>
            </figure>
            <h4>Flexibility</h4>
            <p>
              Only by combining test strategies with a formal analytics framework, can a clear picture emerge. We focus on providing that framework and actionable recommendations.
            </p>
            {/*<ul>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>*/}
          </div>
          <div className="container-xs center-content">
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/coronavirus.jpg')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">Credit: Veronica Falconieri Hays; Source: Lorenzo Casalino, Zied Gaieb and Rommie Amaro, U.C. San Diego (spike model with glycosylations)</figcaption>
            </figure>
          </div>


        </GenericSection>

      </React.Fragment>
    );
  }
}


export default CovidView;
