import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import ColorimetrySplit from '../components/sections/ColorimetrySplit';
import GenericSection from '../components/sections/GenericSection';

// Import Helmet
import Helmet from "react-helmet";


class Colorimetry extends React.Component {

  render() {

    const colorimetrySectionHeader = {
      title: 'Trade-offs'
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>ROSA Scientific | Colorimetry</title>
        </Helmet>

        <ColorimetrySplit invertMobile topDivider imageFill />

        <GenericSection>
          <div className="container-xs">
            <SectionHeader data={colorimetrySectionHeader} className="center-content" />
            <div className="center-content" style={{'marginTop': '-30px'}}>
              <p>
                Despite their benefits (cost and availability), paper-based immunoassays are not without trade-offs, among other things, the medium (eg porosity), the environment (eg humidity), probe instability and other variabilities, and they require above-threshold concentrations.
              </p>
            </div>
          </div >
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Gold Standard</h2>
            <p>
            </p>
            <p>
              New techniques, including photometry and autonomous fluidic circuits will allow such tests to become the gold standard for antibody-antigen quantitation.
              Coupled with machine learning approaches, smart-test capabilities can be added to new and existing assays, alike.
              Contact us for more information about augmenting a test.
            </p>
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}


export default Colorimetry;
