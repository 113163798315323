import React from 'react';

import { useState } from "react";
import { renderToString } from 'react-dom/server';


const makeSearchableContent = (SearchComponent, content) => {
    const searchContent = renderToString(<SearchComponent />);
    return {
      display: content,
      content: searchContent
    };
};

const useClientSearch = (list, initial="", callBack = item => item) => {
  const [query, setQuery] = useState(initial);

  const searchList =
    query.length > 0
      ? list.filter(item => String(callBack(item)).includes(query))
      : list;

  return { query, setQuery, searchList };
};

export { makeSearchableContent, useClientSearch };



//////////////////////////////////////////////

// To-do allow searchable trees instead of serializing the entire rendered view
//
// import ShallowRenderer from 'react-test-renderer/shallow';
//
// const getText = (tree) => {
//   if (typeof tree === 'string') {
//     return tree;
//   }
//   return tree.props.children.entries((key, child) => {
//     getText(child);
//   });
// }
//
// const makeSearchableContent = (SearchComponent, content) => {
//     const renderer = new ShallowRenderer();
//     renderer.render(<SearchComponent />);
//     const text = getText(renderer.getRenderOutput());
